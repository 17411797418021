<template >
  <div>
    <v-navigation-drawer id="core-navigation-drawer" class="globalRadiusDrawer" :dark="$store.state.isDarkMode"
      v-model="drawer" :expand-on-hover="expandOnHover" :right="detectLang" :src="barImage" mobile-break-point="960" app
      width="260" v-bind="$attrs" hide-overlay>
      <template v-slot:img="props">
        <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
      </template>
      <v-divider class="mb-1" />
      <v-list dense nav>
        <v-list-item @click="drawerOff" style="cursor: pointer;" class="py-0":class="{ logoRtl: $i18n.locale == 'en' }">
        
          
         <v-list-item-avatar class="align-self-center"  contain>
          </v-list-item-avatar>
          <v-list-item-content ><h3 >{{ $t("Incentive School") }}</h3></v-list-item-content>


          <!-- <v-list-item-avatar class="align-self-center" color="white" contain>
            <v-img  src="@/assets/robogex.png" class="img-logo" max-height="30" />
          </v-list-item-avatar>
          <v-list-item-content style="font-size: smaller;">Robogeex Dashboard</v-list-item-content>  -->
          <!-- {{ $t("Bahloul Dashboard") }} -->

        </v-list-item>
      </v-list>
      <v-row class="py-3"  style="align-items: center; justify-items: center; text-align: center; "> 
        <!-- <v-col v-if="termModel" md="6" style="font-size: x-small;">{{ termModel.name }}</v-col> <v-col md="6" style=" font-size: x-small;">{{ termModel.educational_year }}</v-col> -->
      </v-row>
      <v-divider   />
      <v-list :class="{ drawerItemRtl: $i18n.locale == 'en' }" expand>
        <div />

        <template v-for="(item, i) in computedItems">
          <template v-if="item.enterRoute">
            <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">

            </base-item-group>
            <base-item v-else :key="`item-${i}`" :item="item" />
          </template>
        </template>

        <div />
      </v-list>
      <!-- <v-divider class="mb-2" />
      <template>
        <div>
          <base-item :class="{ outRtL: $i18n.locale == 'en' }" :item="{
            title: $t('logout'),
            icon: 'mdi-arrow-right-bold',
            to: '/Login',

          }" />
        </div>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // termModel: JSON.parse(localStorage.getItem("termModel")),
    items: this.$store.state.drawerList,
    // items: [
    // {
    //     icon: "mdi-robot",
    //     title: "Ai",
    //     role: [
          
    //     ],
    //     enterRoute: true,
    //     children: [
    //     {
    //         icon: "mdi-chart-bar",
    //         title: "Reports",
    //         to: "/aiIndexReport",
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-view-dashboard-edit-outline",
    //         title: "Ai Dashbord",
    //         to: "/aiDashbordIndex",
    //         role: [],
    //         enterRoute: true,
    //       },
    //       // {
    //       //   icon: "mdi-star-outline",
    //       //   title: "Warehouse",
    //       //   to: "/warehouse",
    //       //   role: ['delivery_note:index', 'delivery_note:orders',],
    //       //   enterRoute: false,
    //       // },
    //     ],
    //   },
    //   {
    //     icon: "mdi-file-cabinet",
    //     title: "inventory",

    //     // To be filled with the required permission
    //     role: [
    //       'inventory:show',
    //       'inventory:auto-complete',
    //       'inventory:store',
    //       'inventory:update',
    //       'inventory:delete',
    //       'inventory-folder:index',
    //       'inventory-folder:store',
    //       'inventory-folder:auto-complete',
    //       'inventory-folder:update',
    //       'inventory-folder:show',
    //       'inventory-folder:delete',
    //       'inventory-material:store',
    //       'inventory-material:update',
    //       'inventory-material:destroy',
    //       'inventory-material:auto-complete',
    //       'inventory-material:store-material-attribute',
    //       'inventory-material:update-material-attribute',
    //       'inventory-material:destroy-material-attribute',
    //       'invoice-type:index',
    //       'invoice-type:store',
    //       'invoice-type:update',
    //       'invoice-type:show',
    //       'invoice-type:delete',
    //       'invoice-template:index',
    //       'invoice-template:store',
    //       'invoice-template:update',
    //       'invoice-template:show',
    //       'invoice-template:delete',
    //       'invoice:index',
    //       'invoice:store',
    //       'invoice:update',
    //       'invoice:toggle-lock',
    //       'invoice:show',
    //       'invoice:delete'
    //     ],
    //     enterRoute: true,
    //     children: [
    //       {
    //         icon: "mdi-file-cabinet",
    //         title: "inventory",
    //         // To be filled with the required permission
    //         enterRoute: true,
    //         to: "/inventories",
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "material-tree",
    //         // To be filled with the required permission
    //         enterRoute: true,
    //         to: "/material-tree",
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "bill-management",
    //         to: "/bill-management",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "Bills",
    //         to: "/invoice-list",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "Material Types",
    //         to: "/material-types",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "invoiceTemplate",
    //         to: "/invoice-Template",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Inventory Reports",
    //         to: "/inventoryReports",
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-sitemap-outline",
    //     title: "WorkFlow",
    //     role: [
    //       'workflow:purchase',
    //       'workflow:sales',
    //       'workflow:order:update',
    //       'workflow:order:show',
    //       'workflow:order:delete',
    //       'delivery_note:index',
    //       'delivery_note:delete',
    //       'delivery_note:orders',
    //       'delivery_note:show',
    //       'delivery_note:update',
    //       'delivery_note:store',
    //       'workflow:order:confirm',
    //       'workflow:approve',
    //       'workflow:to_invoice',
    //     ],
    //     enterRoute: true,
    //     children: [
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Purchase Management",
    //         to: "/purchaseManagement",
    //         role: ['workflow:purchase',],
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Sales Management",
    //         to: "/salesManagement",
    //         role: ['workflow:sales',],
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Warehouse",
    //         to: "/warehouse",
    //         role: ['delivery_note:index', 'delivery_note:orders',],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-currency-usd",
    //     title: "accounting",
    //     enterRoute: false,
    //     role: [
    //       "folder:index",
    //       "folder:store",
    //       "account:store",
    //       "entry:index",
    //       "entry-template:index",
    //       "financial-order:index",
    //       'app_financial_order:index',
    //       "financial-order:store",
    //     ],
    //     children: [
    //       {
    //         icon: "mdi-family-tree",
    //         title: "accounting tree",
    //         to: "/accounting",
    //         role: [
    //           "folder:index",
    //           "folder:store",
    //           "account:store",
    //           "cost-center:index",
    //         ],
    //         enterRoute: false,

    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Sub Accounts",
    //         to: "/subAccount",
    //         role: [
    //           "sub_account:index",
    //           "sub_account:store",
    //         ],
    //         enterRoute: false,

    //       },
    //       {
    //         icon: "mdi-currency-usd",
    //         title: "cost center",
    //         to: "/costCenters",
    //         role: ["cost-center:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-transit-connection-horizontal",
    //         title: "Accounting entry",
    //         to: "/accountingEntry",
    //         role: ["entry:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-apps-box",
    //         title: "accounting templates",
    //         to: "/accountingTemplates",
    //         role: ["entry-template:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "financial orders",
    //         to: "/financialOrder",
    //         role: ["financial-order:index", "financial-order:store"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Financial Orders Parents",
    //         to: "/financialOrderParents",
    //         role: ["app_financial_order:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "Document Management",
    //         to: "/document-management",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-family-tree",
    //         title: "Documents",
    //         to: "/documents-list",
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "pay installments",
    //         to: "/payInstallments",
    //         role: ["financial-order:index", "financial-order:store"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Financial Reports",
    //         to: "/reports",
    //         role: ['account:trailbalance','account:statement','tax:report','digital_wallet_record:report'],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-shape-circle-plus",
    //         title: "twirling",
    //         to: "/accountingRotation",
    //         role: ["cost-center:index"],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-silverware",
    //     title: "Restaurant",
    //     enterRoute: false,
    //     role: ["product:index", "purchase-history:store"],
    //     children: [
    //       {
    //         icon: "mdi-cart-variant",
    //         title: "Point Of Sale",
    //         to: "/buffet",
    //         enterRoute: false,
    //         role: ["purchase-history:store", "product:index"],
    //       },
    //       {
    //         icon: "mdi-food-fork-drink",
    //         title: "meals",
    //         role: ["meal:index"],
    //         to: "/meal",
    //         enterRoute: false,
    //       },
    //       // {
    //       //   icon: "mdi-history",
    //       //   title: "ordered meals",
    //       //   to: "/orderedMeals",
    //       //   role: ["student_meal:index"],
    //       //   enterRoute: false,
    //       // },
    //       {
    //         icon: "mdi-food-outline",
    //         title: "Meal Manufacturing",
    //         to: "/mealManufacturing",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       // {
    //       //   icon: "mdi-invoice-list-outline",
    //       //   title: "orders invoices",
    //       //   to: "/ordersInvoices",
    //       //   role: [],
    //       //   enterRoute: false,
    //       // },
    //       {
    //         icon: "mdi-food-variant",
    //         title: "Food Categories",
    //         to: "/foodCategories",
    //         role: ["student_meal:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Meals Reports",
    //         to: "/meal/reports",
    //         role: ["student_meal:index"],
    //         enterRoute: false,
    //       },
    //       // {
    //       //   icon: "mdi-baguette",
    //       //   title: "cafeteria products",
    //       //   to: "/buffetProducts",
    //       //   role: ["product:index"],
    //       //   enterRoute: false,
    //       // },
    //     ],
    //   },
    //   {
    //     icon: "mdi-account-key",
    //     title: "cashier",
    //     role: [
    //       "financial-order:index",
    //       "cashier-box:show",
    //       "cashier-box:index",
    //     ],
    //     enterRoute: false,
    //     children: [
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Cashier for financial orders",
    //         to: "/cashierFinancialOrder",
    //         role: ["financial-order:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-account-group",
    //         title: "treasurers",
    //         to: "/cashierBox",
    //         role: ["cashier-box:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-archive",
    //         title: "boxes",
    //         to: "/myBoxes",
    //         enterRoute: false,
    //         role: ["cashier-box:show"],
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-account-tie",
    //     title: "roles",
    //     to: "/roles",
    //     enterRoute: false,
    //     role: ["role:index"],
    //   },
    //   {
    //     icon: "mdi-account",
    //     title: "students",
    //     role: ["student:index", "final_grade:student:index"],
    //     enterRoute: false,
    //     children: [
    //       {
    //         icon: "mdi-star-outline",
    //         title: "public record",
    //         to: "/students",
    //         role: ["student:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Results Document",
    //         to: "/graduation",
    //         role: ["final_grade:student:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Variables",
    //         to: "/variablesStudent",
    //         role: ["school_variable:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-account-group",
    //         title: "temporary zone",
    //         to: "/temporary-area",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Students Reports",
    //         to: "/students/reports",
    //         role: ["absent:report", "mark:report"],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-highway",
    //     title: "routes",
    //     role: ["route:index", "driver:index", "trip:report", "student_trip:report"],
    //     enterRoute: false,
    //     children: [
    //       {
    //         icon: "mdi-highway",
    //         title: "Trips",
    //         role: ["route:index"],
    //         enterRoute: false,
    //         to: "/driversRoutes",
    //       },
    //       {
    //         icon: "mdi-car",
    //         title: "drivers",
    //         role: ["driver:index"],
    //         to: "/drivers",
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Drivers Reports",
    //         to: "/drivers/reports",
    //         role: ["trip:report", "student_trip:report"],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-account-supervisor-circle",
    //     title: "parents",
    //     to: "/parents",
    //     enterRoute: false,
    //     role: ["parent:index"],
    //   },
    //   {
    //     icon: "mdi-book-open-variant",
    //     title: "Educational section",

    //     role: [
    //       'teacher:store',
    //       'teacher:show',
    //       'teacher:index',
    //       'teacher:delete',
    //       'teacher:update', "edu-class:index", "edu-class:store", "edu-class:show", "edu-class:update", "edu-class:destroy",
    //       'responsibility:store',
    //       'responsibility:index',
    //       'responsibility:show',
    //       'responsibility:delete',
    //       'responsibility:update',
    //       'lesson:index',
    //     ],
    //     enterRoute: false,
    //     children: [
    //       {
    //         icon: "mdi-account-multiple",
    //         title: "teachers",
    //         to: "/teachers",
    //         role: ['teacher:index'],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-book-open",
    //         title: "Weekly classes",
    //         to: "/weekly-classes",
    //         role: ['responsibility:index',],
    //         enterRoute: false,
    //       },

    //       {
    //         icon: "mdi-book-multiple",
    //         title: "lessons",
    //         to: "/teacher-lessons",
    //         role: ["lesson:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-book",
    //         title: "Homeworks",
    //         to: "/homeworks",
    //         role: ["student:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-attachment",
    //         title: "attachments",
    //         to: "/attachments",
    //         role: ["student:index"],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "Reports",
    //         to: "/reportTechers",
    //         role: ["student:index"],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-sitemap-outline",
    //     title: "L.M.S",
    //     role: [ 'question:index','quizze:index','lms:reports'
    //     ],
    //     enterRoute: true,
    //     children: [
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Questions",
    //         to: "/questionsLms",
    //         role: ['question:index'],
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Quizzes",
    //         to: "/quizzesLms",
    //         role: ['quizze:index'],
    //         enterRoute: true,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "Reports",
    //         to: "/lmsReports",
    //         role: ['lms:reports'],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-account-star",
    //     title: "admins",
    //     to: "/admins",
    //     enterRoute: false,
    //     role: ["user:index"],
    //   },
    //   {
    //     icon: "mdi-counter",
    //     title: "main views",
    //     to: "/mainViews",
    //     enterRoute: false,
    //     role: [
    //       "educational-year:index",
    //       "term:index",
    //       "term-type:index",
    //       "grade:index",
    //       "grade-type:index",
    //       "floor:index",
    //       "room:index",
    //       "edu-class:index",
    //       "subject:index",
    //     ],
    //   },
    //   {
    //     // list test
    //     icon: "mdi-human-capacity-increase",
    //     title: "HR",
    //     enterRoute: false,
    //     role: [
    //       "folder:index",
    //       "folder:store",
    //       "account:store",
    //       "entry:index",
    //       "entry-template:index",
    //       "financial-order:index",
    //       "financial-order:store",
    //     ],
    //     children: [
    //       {
    //         icon: "mdi-cog",
    //         title: "iniate",
    //         to: "/HR/initiate",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "public record",
    //         to: "/HR/PublicRecord",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.time-attendance",
    //         to: "/HR/time-attendance",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.vacations",
    //         to: "/HR/vacations",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.variations",
    //         to: "/HR/variations",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.loans",
    //         to: "/HR/loans",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.calculations",
    //         to: "/HR/calculations",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-star-outline",
    //         title: "hr.payments",
    //         to: "/HR/payments",
    //         role: [],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-chart-bar",
    //         title: "HR Reports",
    //         to: "/HR/reports",
    //         role: [],
    //         enterRoute: false,
    //       },
    //     ],
    //   },
    //   {
    //     icon: "mdi-mail",
    //     title: "Mailing",
    //     enterRoute: false,
    //     role: [
    //       'message:index',
    //       'message:show',
    //       'message:mail',
    //       'message:send_mail',
    //       'message:relpy',
    //       'message:update',
    //       'message:document',
    //       'message:document',
    //       'message:delete',
    //       'task:index',
    //       'task:receive',
    //       'task:send',
    //       'task:export',
    //       'task:done',
    //       'task:approve',
    //     ],
    //     children: [
    //       {
    //         icon: "mdi-mail",
    //         title: "Incoming Mail",
    //         to: "/incomingMail",
    //         role: ['message:mail',],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-mail",
    //         title: "Messages Sent",
    //         to: "/messagesSent",
    //         role: ['message:send_mail'],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-mail",
    //         title: "All Messages",
    //         to: "/allMessages",
    //         role: ['message:index',],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-handshake",
    //         title: "My Tasks",
    //         to: "/myTasks",
    //         role: ['task:receive',],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-handshake",
    //         title: "Tasks Sent",
    //         to: "/tasksSent",
    //         role: ['task:send',],
    //         enterRoute: false,
    //       },
    //       {
    //         icon: "mdi-handshake",
    //         title: "All Tasks",
    //         to: "/allTasks",
    //         role: ['task:index',],
    //         enterRoute: false,
    //       },
    //     ]
    //   }




    // ],
  }),
  watch: {
    "$store.state.drawerList": function (){
      console.log(this.$store.state.drawerList);
      this.items = this.$store.state.drawerList;
      console.log("lskdjfkjdfksljflksjdflksdjf");
      console.log("lskdjfkjdfksljflksjdflksdjf");
      console.log(this.items);
      console.log("lskdjfkjdfksljflksjdflksdjf");
      console.log("lskdjfkjdfksljflksjdflksdjf");

    }
  },
  created() {
    this.items = JSON.parse(localStorage.getItem('DrawerList'))!=null ? JSON.parse(localStorage.getItem('DrawerList')) : this.items;
    
    for (const item of this.items) {
      if (this.getCurrentUserAbilities.includes("*")) {
        item.enterRoute = true;
        if (item.children) {
          item.children.forEach((el) => {
            el.enterRoute = true;
          });
        }
      } else {
        for (let role of item.role) {
          if (
            item.role.some((el) => this.getCurrentUserAbilities.includes(el))
          ) {
            item.enterRoute = true;
          } else {
            item.enterRoute = false;
          }
        }
        if (item.children) {
          for (let child of item.children) {
            if (
              child.role &&
              Array.isArray(child.role)) {
              if (
                child.role.some((el) => this.getCurrentUserAbilities.includes(el))
              ) {
                child.enterRoute = true;
              } else {
                child.enterRoute = false;
              }
            }
          }
        }
      }
    }
    // this.termModel =  JSON.parse(localStorage.getItem("termModel"));
  },
  computed: {
    detectLang() {
      if (this.$i18n.locale == "en") {
        return false;
      } else {
        return true;
      }
    },
    getCurrentUserAbilities() {
      return this.$store.getters.getUserAbilities;
    },

    ...mapState(["barColor", "barImage","drawerList"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },

    computedItems() {
      this.items = this.$store.state.drawerList;
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        // title: this.$t("avatar"),
      };
    },
  },

  methods: {
    drawerOff(){
      this.$store.state.drawer = false;
      this.$store.state.activeDash = true;
      this.$router.push("/");
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.drawerItemRtl
  direction: ltr
  .v-list-item__title
    margin-left: 20px

.logoRtl
  direction: ltr
  .v-list-item__content
    margin-left: 10px

.outRtL
  direction: ltr
  .v-list-item__title
    margin-left: 20px

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
            margin-left: 8px
</style>
